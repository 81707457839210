$(document).ready(function() {
    //Google Map
    if ($("#map_canvas").length) {  //check if map exists
        function initialize() {

            var myLatlng = new google.maps.LatLng(52.912054, -1.234023);
            var mapCenter = new google.maps.LatLng(52.912054, -1.234023);
            var mapCanvas = document.getElementById('map_canvas');
            var mapOptions = {
                center: mapCenter,
                zoom: 15,
                scrollwheel: false,
                draggable: true,
                disableDefaultUI: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            }

            var map = new google.maps.Map(mapCanvas, mapOptions)

            map.set('styles', [{
                featureType: 'landscape',
                elementType: 'geometry',
                stylers: [{
                    hue: '#ffff00'
                }, {
                    saturation: 30
                }, {
                    lightness: 10
                }]
            },]);

            var contentString =
                '<div id="content">' +
                '<div id="siteNotice">' +
                '</div>' +
                '<h1 id="firstHeading" class="firstHeading">Car Doctors Chilwell</h1>' +
                '<div id="bodyContent" class="bodyContent">' +
                '<p>202-204 Bye Pass Road</p>' +
                '<p>Chilwell</p>' +
                '<p>Nottingham</p>' +
                '<p>NG9 5HL</p>' +
                '<p><strong>Tel: 0115 9253639</strong></p>' +
                '</div>' +
                '</div>';

            var infowindow = new google.maps.InfoWindow({
                content: contentString
            });

            var marker = new google.maps.Marker({
                position: myLatlng,
                map: map,
                animation: google.maps.Animation.DROP,
                title: 'Car Doctors Chilwell'
            });

            setTimeout(function () {
                infowindow.open(map, marker);
            }, 1000);

            google.maps.event.addListener(marker, 'click', function () {
                infowindow.open(map, marker);
            });
        }

        google.maps.event.addDomListener(window, 'load', initialize);
    }
    if ($("#map_canvas2").length) {  //check if map exists
        function initialize2() {

            var myLatlng = new google.maps.LatLng(52.929785, -1.218960);
            var mapCenter = new google.maps.LatLng(52.929785, -1.218960);
            var mapCanvas = document.getElementById('map_canvas2');
            var mapOptions = {
                center: mapCenter,
                zoom: 15,
                scrollwheel: false,
                draggable: true,
                disableDefaultUI: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            }

            var map = new google.maps.Map(mapCanvas, mapOptions)

            map.set('styles', [{
                featureType: 'landscape',
                elementType: 'geometry',
                stylers: [{
                    hue: '#ffff00'
                }, {
                    saturation: 30
                }, {
                    lightness: 10
                }]
            },]);

            var contentString =
                '<div id="content">' +
                '<div id="siteNotice">' +
                '</div>' +
                '<h1 id="firstHeading" class="firstHeading">Car Doctors Beeston</h1>' +
                '<div id="bodyContent" class="bodyContent">' +
                '<p>93 Wollaton Road</p>' +
                '<p>Beeston</p>' +
                '<p>Nottingham</p>' +
                '<p>NG9 2NP</p>' +
                '<p><strong>0115 9431658</strong></p>' +
                '</div>' +
                '</div>';

            var infowindow = new google.maps.InfoWindow({
                content: contentString
            });

            var marker = new google.maps.Marker({
                position: myLatlng,
                map: map,
                animation: google.maps.Animation.DROP,
                title: 'Car Doctors Beeston'
            });

            setTimeout(function () {
                infowindow.open(map, marker);
            }, 1000);

            google.maps.event.addListener(marker, 'click', function () {
                infowindow.open(map, marker);
            });
        }

        google.maps.event.addDomListener(window, 'load', initialize2);
    }
});

