$(document).ready(function() {
    //Banner Slides
    $('.slidecontainer')
        .cycle({
            fx: 'fade',
            slides: '.hero',
            speed: 'slow',
            timeout: 6000,
            width: '100%',
            fit: 1
        });

    //Testimonial Slides
    $('.testimonial-wrap')
        .cycle({
            fx: 'fade',
            slides: '.testimonial-text',
            speed: 'slow',
            timeout: 6000,
            width: '100%',
            fit: 1
        });

    //Menu Toggle
    var menuToggle = $('#js-mobile-menu').unbind();
    $('#js-navigation-menu').removeClass("show");

    menuToggle.on('click', function (e) {
        e.preventDefault();
        $('#js-navigation-menu').slideToggle(function () {
            if ($('#js-navigation-menu').is(':hidden')) {
                $('#js-navigation-menu').removeAttr('style');
            }
        });
    });

    //Form Validation
    $("#contact-form").validate({
        errorLabelContainer: $("#contact-form div.disp-error")
    });

    $("#booking-form").validate({
        errorLabelContainer: $("#booking-form div.disp-error")
    });

    //Swipebox
    $('.swipebox').swipebox({
        hideBarsDelay: 10000
    });

    //Match element heights
    $('.equalise').matchHeight();


});

